<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill-rule="evenodd"
      d="M10,8 L37,8 L37,26 L10,26 L10,8 M23.5,12.5 C25.9852814,12.5 28,14.5147186 28,17 C28,19.4852814 25.9852814,21.5 23.5,21.5 C21.0147186,21.5 19,19.4852814 19,17 C19,14.5147186 21.0147186,12.5 23.5,12.5 M16,11 C16,12.6568542 14.6568542,14 13,14 L13,20 C14.6568542,20 16,21.3431458 16,23 L31,23 C31,21.3431458 32.3431458,20 34,20 L34,14 C32.3431458,14 31,12.6568542 31,11 L16,11 M4,14 L7,14 L7,29 L31,29 L31,32 L4,32 L4,14 Z"
    />
  </svg>
</template>
<script>
export default {
  name: 'PlatinCashIcon',
}
</script>
